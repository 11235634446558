<template>
  <div class="about">
    <web-header active-url="/about" borderBottom></web-header>
    <div class="about-one" ref="page1">
      <img src="~@/assets/about_bg.png" />
      <div class="about-one-text">
        <div>中国金融四十人论坛（China Finance 40 Forum，CF40）</div>
        <div>成立于2008年4月12日，</div>
        <div>是目前中国极具影响力的金融专业智库平台，</div>
        <div>专注于经济金融领域的政策研究与交流。</div>
        <a href="/singles/9618" target="_blank">
          <span>查 看 更 多</span>
          <i class="el-icon-arrow-right"></i>
        </a>
      </div>
      <div class="about-one-box">
        <div class="about-one-box-cell">
          <div class="count">100</div>
          <div class="title">闭门研讨</div>
          <div class="tips">每年召开100余场闭门探讨会</div>
        </div>
        <div class="about-one-box-cell">
          <div class="count">60</div>
          <div class="title">国际交流</div>
          <div class="tips">每年开展60余场国际交流</div>
        </div>
        <div class="about-one-box-cell">
          <div class="count">20</div>
          <div class="title">课题研究</div>
          <div class="tips">每年开展20余项课题研究</div>
        </div>
      </div>
    </div>
    <div class="about-two" ref="page2">
      <el-divider>
        <div class="about-two-title">组织架构</div>
      </el-divider>
      <div class="about-two-content">
        <el-image :src="organizeImage"></el-image>
      </div>
    </div>
    <div class="about-three" ref="page3">
      <div class="about-three-title">智库体系</div>
      <div class="about-three-desc">
        以平台为起点，中国金融四十人论坛陆续发起成立上海新金融研究院、北京大学数字金融研究中心、北方新金融研究院、
        上海浦山新金融发展基金会和中国金融四十人研究院，逐步形成“平台+实体”的新型智库模式。
      </div>
      <div class="about-three-content">
        <img src="~@/assets/about_thinks.png" />
      </div>
    </div>
    <div class="about-four" ref="page4">
      <div class="about-four-title">公益项目</div>
      <div class="about-four-content">
        <swiper :options="swiperOption" class="about-four-content-swiper" ref="welfareSwiper">
          <swiper-slide v-for="(item,index) in welfareList" :key="index">
            <div class="slide-item">
              <a :href="createUrl('/article/1/'+item.id, item)" target="_blank">
                <div class="img">
                  <img :src="item.cover_image.img"/>
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="tips">
                  {{ item.introduction }}
                </div>
              </a>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next" slot="button-prev"></div>
        <div class="swiper-button-prev" slot="button-next"></div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperMixin from "./swiperMixin";
import { navigationList, articleList } from "@/api";
export default {
  name: "About",
  mixins: [SwiperMixin],
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // 组织架构banner
      organizeImage: "",
      // 公益项目列表
      welfareList: [],
    }
  },
  created() {
    this.init();
    // 判断存在锚文本，并定位到锚文本
    if (this.$route.hash) {
      this.scrollToAnchor(this.$route.hash);
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.welfareSwiper.initSwiper();
    });
  },
  mounted() {
    // 判断存在锚文本，并定位到锚文本
    if (this.$route.hash) {
      this.scrollToAnchor(this.$route.hash);
    }
  },
  watch: {
    '$route.hash': {
      immediate: true,
      handler(newHash) {
        this.$nextTick(() => {
          this.scrollToAnchor(newHash);
        });
      }
    }
  },
  methods: {
    // 初始化数据
    init() {
      this.getOrganBanner();
      this.getWelfare();
    },
    // 获取组织架构的banner图
    async getOrganBanner() {
      let param = {
        pageSize: 1,
        currentPage: 1,
        orderBy: "",
        sortedBy: "",
        search: "id:27",
        searchFields: "id:="
      };
      let res = await navigationList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.organizeImage = res.data.list[0].banner && res.data.list[0].banner.img ? res.data.list[0].banner.img : "";
        }
      }
    },
    // 获取公益项目
    async getWelfare() {
      let res = await articleList( 1,
          { pageSize: 50,
            currentPage: 1,
            orderBy: "send_time",
            sortedBy: "desc",
            search: "cover_image_id:0|class_id:30|status:1",
            searchFields: "cover_image_id:>|class_id:=|status:="
          });
      if (res && res.code == 200) {
        this.welfareList = res.data.list;
      }
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row && row.jump_link) {
        return row.jump_link;
      }
      return url;
    },
    // 定位到锚文本
    scrollToAnchor(e) {
      if (e && e.indexOf("#") > -1) {
        const element = this.$refs[e.replace(new RegExp("#", 'g'), '')];
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.about {
  &-one {
    position: relative;
    width: 100%;
    padding-bottom: 100px;
    &-text {
      position: absolute;
      top: 15%;
      left: 28%;
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
      a {
        margin-top: 30px;
        padding: 5px 18px;
        font-size: 12px;
        background-color: #e97a47;
        display: ruby-text;
        i {
          padding-left: 10px;
        }
        &:hover {
          background-color: #e4895f;
        }
      }
    }
    &-box {
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      &-cell {
        width: 33.33%;
        padding-top: 50px;
        text-align: center;
        .count {
          font-size: 50px;
          color: #e97a47;
        }
        .title {
          font-size: 16px;
          margin-top: 20px;
        }
        .tips {
          margin-top: 5px;
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
  &-two {
    padding: 0 15%;
    margin-top: 100px;
    &-title {
      font-size: 36px;
      font-family: "HarmonyOS_Bold";
      padding: 0 15px;
    }
    &-content {
      text-align: center;
    }
  }
  &-three {
    padding: 80px 15%;
    background-color: #f2f2f7;
    &-title {
      font-size: 36px;
      font-family: "HarmonyOS_Bold";
      text-align: center;
    }
    &-desc {
      padding: 50px 30px;
      text-align: center;
      font-size: 24px;
    }
    &-content {
      text-align: center;
      padding: 30px 0 50px 0;
    }
  }
  &-four {
    padding: 60px 15%;
    &-title {
      font-size: 36px;
      font-family: "HarmonyOS_Bold";
      text-align: center;
    }
    &-content {
      position: relative;
      padding: 70px 5%;
      &-swiper {
        .slide-item {
          color: #1e1e1e;
          img {
            text-align: center;
          }
          .title {
            color: #999999;
            margin-top: 20px;
            font-size: 18px;
          }
          .tips {
            margin-top: 15px;
            text-align: justify;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }
          &:hover,&.active {
            .title {
              color: #1e1e1e;
              font-family: "HarmonyOS_Bold";
            }
          }
        }
      }
      .swiper-button-next, .swiper-button-prev {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border: 1px solid #ececed;
        border-radius: 50%;
        &:after {
          font-size: 16px;
          color: #ee7840;
        }
        &:hover {
          color: #ffffff;
          background: #ee7840;
          border-color: #ee7840;
          &:after {
            color: #ffffff;
          }
        }
        &.swiper-button-disabled {
          opacity: 1 !important;
          cursor: pointer !important;
          pointer-events: auto !important;
        }
        &.swiper-button-prev {
          left: 0;
        }
        &.swiper-button-next {
          right: 0;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about {
    &-one {
      padding: 0;
      &-text {
        top: 8%;
        left: 26%;
        font-size: 14px;
        line-height: 26px;
        a {
          margin-top: 25px;
          padding: 3px 10px;
          i {
            padding-left: 5px;
          }
        }
      }
      &-box {
        position: static;
        width: 100%;
        left: 0;
      }
    }
    &-two {
      padding: 0 30px;
      &-title {
        font-size: 30px;
        padding: 0
      }
    }
    &-three {
      padding: 60px 30px;
      &-desc {
        padding: 30px;
        font-size: 22px;
      }
    }
    &-four {
      padding: 50px 30px;
      &-content {
        padding: 30px 50px;
        &-swiper {
          .slide-item {
            .title {
              margin-top: 10px;
              font-size: 16px;
            }
            .tips {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .about {
    &-one {
      padding: 0;
      &-text {
        top: 3%;
        left: 7%;
        font-size: 12px;
        line-height: 20px;
        a {
          position: absolute;
          margin-top: 0;
          right: -20px;
          bottom: 0px;
          padding: 1px 6px;
          i {
            padding-left: 2px;
          }
        }
      }
      &-box {
        position: static;
        width: 100%;
        left: 0;
        justify-content: space-around;
        &-cell {
          width: 30%;
          padding-top: 10px;
          .count {
            font-size: 40px;
          }
          .title {
            font-size: 14px;
            margin-top: 0;
          }
          .tips {
            font-size: 12px;
          }
        }
      }
    }
    &-two {
      padding: 0 30px;
      margin-top: 50px;
      &-title {
        font-size: 22px;
        padding: 0
      }
    }
    &-three {
      padding: 60px 30px;
      &-title {
        font-size: 22px;
      }
      &-desc {
        padding: 30px 5px;
        font-size: 16px;
      }
      &-content {
        padding: 20px 0;
      }
    }
    &-four {
      padding: 30px;
      &-title {
        font-size: 22px;
      }
      &-content {
        padding: 30px 30px;
        &-swiper {
          .slide-item {
            .title {
              height: 42px;
              margin-top: 10px;
              font-size: 14px;
              overflow: hidden;
            }
            .tips {
              margin-top: 5px;
              font-size: 12px;
            }
          }
        }
        .swiper-button-next, .swiper-button-prev {
          width: 20px;
          height: 20px;
          &:after {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>